import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-y-acceder',
  templateUrl: './comment-y-acceder.component.html',
  styleUrls: ['./comment-y-acceder.component.css']
})
export class CommentYAccederComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
