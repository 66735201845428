<h1>Programme du colloque</h1>

<table>
  <tr>
    <td>8 h 30</td>
    <td>Accueil des participants</td>
  </tr>

  <tr>
    <td>9 h 00</td>
    <td><strong> Nathalie Duriez</strong><br>
      Ouverture du Colloque - Pourquoi s’intéresser au haut potentiel en 2022 ?</td>
  </tr>
  
  <tr>
    <td></td>
    <td><h3>Session 1 : Recherches</h3></td>
  </tr>

  <tr>
    <td>9 h 15</td>
    <td><strong>Nicolas Gauvrit</strong><br>
      Peut-on être trop intelligent pour être sain d’esprit ?</td>
  </tr>

  <tr>
    <td>9 h 45</td>
    <td><strong>Maria Pereira Da Costa et Marion Botella</strong><br>
      Haut potentiel Intellectuel et théorie des over-excitabilities (Hyperstimulabilités)</td>
  </tr>
  
  <tr>
    <td>10 h 15</td>
    <td>
      <strong>Solenn Kermarrec et Sylvie Tordjman</strong><br>
      La question de l'anxiété et des hypersensibilités émotionnelles et sensorielles chez les enfants à haut potentiel : apports de la recherche
    </td>
  </tr>

  <tr>
    <td>11 h 00</td>
    <td><em>Pause</em></td>
  </tr>

  <tr>
    <td>11 h 30</td>
    <td>E-posters : <strong>Florent Cheymol, Sabine Kovacshazy, Clément Pichol-Thievend, Pascale Guillou-Estève, Marie-Alix Lethu et Nathalie Duriez</strong></td>
  </tr>
  
  <tr>
    <td>11 h 45</td>
    <td><strong>Nathalie Clobert et Nicolas Gauvrit</strong><br>
      Le CASS, l’échelle de sensibilité francophone</td>
  </tr>

  <tr>
    <td>12 h 15</td>
    <td>Discussion avec la salle</td>
  </tr>
  
  <tr>
    <td>12 h 45 – 14 h</td>
    <td class="dej">Pause déjeuner</td>
  </tr>

  <tr>
    <td></td>
    <td><h3>Session 2 : Clinique</h3></td>
  </tr>

  <tr>
    <td>14 h</td>
    <td>
      <strong>Sophie Brasseur et Catherine Cuche</strong><br>
      Haut potentiel et hyperstimulabilité/haute sensibilité, quand les spécificités s'emmêlent.
    </td>
  </tr>
  
  <tr>
    <td>14 h 45</td>
    <td>
      <strong>Nathalie Clobert</strong><br>
      L’art de différencier les différences : accompagner les personnes HPI et les personnes (très) sensibles
    </td>
  </tr>
  
  <tr>
    <td>15 h 15</td>
    <td>
      <strong>Vincent Amelot, Marjorie Camus Charron et Virginie Colas</strong><br>
      Elèves à haut potentiel ou scolairement brillants : effets de l’accélération du cursus scolaire perçu par le jeune, l’enseignant et les parents
    </td>
  </tr>

  <tr>
    <td>15 h 35</td>
    <td>E-posters : <strong>Célia Bibard, Vanina Spacensky Riff, Gérald Maes, Mireille Ramone et Nathalie Duriez</strong></td>
  </tr>
  
  <tr>
    <td>15 h 50</td>
    <td><em>Pause</em></td>
  </tr>
  

  <tr>
    <td>16 h 20	</td>
    <td>
      <strong>Catherine Weismann-Arcache</strong><br>
      Le spectre des hauts potentiels, ni Q.I, ni maladie. Quelles réalités cliniques et sociétales ?
    </td>
  </tr>

  <tr>
    <td>16 h 50</td>
    <td>
      <strong>Ana Richet</strong><br>
      Accompagner les HPI avec la thérapie par l’Intégration du cycle de vie (ICV).
    </td>
  </tr>
  
  <tr>
    <td>17 h 10</td>
    <td>
      <strong>Nathalie Duriez</strong><br>
      L’accompagnement des familles à transaction intense.<br>
      La thérapie familiale centrée sur la régulation émotionnelle.
    </td>
  </tr>
  
  <tr>
    <td>17 h 30</td>
    <td>Discussion avec la salle</td>
  </tr>
  
  <tr>
    <td>18 h 00</td>
    <td>Conclusion et clôture de la journée</td>
  </tr>
  <tr>
    <td></td>
    <td>
      <h4><a href="assets/ProgrammeHPI.pdf">Programme détaillé</a></h4>
    </td>
  </tr>

</table>
