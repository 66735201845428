import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { CommentYAccederComponent } from './comment-y-acceder/comment-y-acceder.component';
import { IntervenantsComponent } from './intervenants/intervenants.component';
import { ProgrammeComponent } from './programme/programme.component';

const routes: Routes = [
  {path: "", component: AccueilComponent},
  {path: "programme", component: ProgrammeComponent},
  {path: "intervenants", component: IntervenantsComponent},
  {path: "comment-y-acceder", component: CommentYAccederComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
