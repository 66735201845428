import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccueilComponent } from './accueil/accueil.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ProgrammeComponent } from './programme/programme.component';
import { IntervenantsComponent } from './intervenants/intervenants.component';
import { CommentYAccederComponent } from './comment-y-acceder/comment-y-acceder.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
   declarations: [
      AppComponent,
      AccueilComponent,
      NavBarComponent,
      ProgrammeComponent,
      IntervenantsComponent,
      CommentYAccederComponent,
      FooterComponent
   ],
   imports: [
	 BrowserModule,
	 AppRoutingModule
	],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
