<h1>APPROCHES PLURIELLES DU HAUT POTENTIEL ET DE LA HAUTE SENSIBILITÉ</h1>
<p class="content">
  Les mutations psycho-sociétales ont amené les cliniciens à s’intéresser davantage aux émotions et aux troubles de la régulation émotionnelle.
  De plus en plus dans notre pratique clinique, nous rencontrons des patients en difficultés avec leurs émotions.
  Dans les contextes de supervision, nous entendons les collègues poser le diagnostic de « haut potentiel », là où quelques années auparavant nous aurions parlé de patient borderline.
  Ces patients sont venus consulter avec un symptôme : troubles anxieux, dépression, addictions, conduites à risque, burn out au travail, burn out maternel, difficultés relationnelles, etc.
  Leurs compétences verbales, leur façon de questionner le cadre, leur sensibilité interpellent le thérapeute.
  La théorie de Dabrowski (1964) sur l’hyperstimulabilité s’avère un outil particulièrement bien adapté pour travailler avec ces profils atypiques.
  L’hyperstimulabilité permet de comprendre une réactivité organique et mentale plus intense chez certains sujets pour lesquels la réponse comportementale peut sembler disproportionnée par rapport aux stimuli. D’autres parleront d’ « hypersensibilité » ou de « haute sensibilité ».
  Nous souhaitons durant cette journée d’étude réfléchir ensemble à ces notions de « haut potentiel », « hyperstimulabilité », « hypersensibilité », « haute sensibilité » et aux modalités de prise en charge de ces patients atypiques, avec des approches thérapeutiques diverses (<em>ICV, thérapie centrée sur la régulation émotionnelle, etc.</em>).
  <br><br>
  Cette journée de colloque se déroulera en format hybride, en présentiel dans l’Amphithéâtre du Campus Condorcet et en distanciel pour ceux qui ne pourront pas se déplacer.

</p>
