<div style="font-size: 18px;">

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/marion.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Marion Botella</h3>
      <p>
        Maîtresse de conférences en psychologie différentielle, Université Paris Cité, Laboratoire de Psychologie et d’Ergonomie Appliquées. Les travaux de recherche de Marion Botella portent principalement sur la description du processus créatif, dans différents domaines (art, science, design, …) et auprès de différentes populations (experts, étudiants, enfants), croisée avec les facteurs multivariés (cognitifs, conatifs, émotionnels et environnementaux). Dans une moindre mesure, ses travaux permettent la construction ou la validation d’outils de mesure (Elève Profileur, échelle de stress, de qualité de vie, d’overexcitability, etc.).
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/sophie.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Sophie Brasseur</h3>
      <p>
        Logopède, Dr. en Psychologie et enseignante à l’Institut Libre Marie Haps (Bruxelles), Sophie Brasseur est également co-coordinatrice de l’ASBL Singularités Plurielles.<br><br>
        Elle s’intéresse à la thématique du Haut Potentiel depuis une vingtaine d’années, ainsi qu’au fonctionnement émotionnel et a réalisé une thèse de doctorat sur l’impact des compétences émotionnelles dans l’intégration sociale et scolaire des jeunes présentant un HPI. Elle a, par ailleurs, co-écrit l’ouvrage Le Haut Potentiel en questions avec Catherine Cuche (Mardaga, 2017).<br><br>
        Elle est également formée au développement des compétences émotionnelles par l’approche neuroscientifique (ANC) et comportementale et a développé le groupe thérapeutique « L’école des émotions ». Elle accompagne beaucoup d’enfants et d’adultes autour de ces questions (tant en ce qui concerne la prise en charge que la réalisation de bilans psychologiques).
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/nathalie-clobert.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Nathalie Clobert </h3>
      <p>
        Psychologue clinicienne, hypnothérapeute, référente-experte HPI chez Oct-Opus Formations, Nathalie Clobert a exercé en service d’hospitalisation et aux consultations de psychiatrie du GHEF. Elle accompagne des personnes à haut potentiel et des personnes dotées d’une sensibilité élevée. Elle intervient comme formatrice sur différents thèmes (Thérapies brèves, Traumatismes, Résilience, HPI).<br><br>
        Elle est l’auteure du roman <em>Le jour où je suis devenue moi-même</em>(Leduc, 2019), des livres Ma Bible de l’hypersensibilité (Leduc, 2021) et Haut potentiel intellectuel et heureux (Leduc, 2022). Avec Nicolas Gauvrit, elle a co-dirigé l’ouvrage Psychologie du haut potentiel (De Boeck, 2021).

      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/catherine-cuche.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Catherine Cuche</h3>
      <p>
        Docteur en psychologie, psychothérapeute familiale, Bruxelles ; enseignante Haute Ecole de Bruxelles-Brabant et Université de Louvain. Ses recherches et sa pratique clinique l’ont amenée à défendre une approche globale de la personne à haut potentiel intellectuel. Elle a ainsi développé un modèle théorique (le MSHPI) qui fait la synthèse des modèles développementaux sur le haut potentiel. Son regard clinique et ses recherches explorent la manière dont les hautes capacités intellectuelles interagissent ou non avec le reste du fonctionnement de la personne. Elle en particulier étudié les croyances motivationnelles et les liens entre émotions et apprentissages chez les personnes à haut potentiel intellectuel. 
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/nathalie-duriez.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Nathalie Duriez</h3>
      <p>
        Maître de conférences-HDR en psychologie clinique, Université Paris 8, Laboratoire Psychopathologie et Processus de Changement – Thérapeute familiale au CSAPA Monceau, Paris – Formatrice à l’approche systémique. Ses recherches portent sur les processus de changement en thérapie familiale et sur la régulation émotionnelle. Elle a développé un modèle de thérapie familiale centrée sur la régulation émotionnelle. Elle est l’auteur de l’ouvrage <em>Changer en famille</em> (Erès, 2009).
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/nicolas.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Nicolas Gauvrit</h3>
      <p>
        Maître de conférences en psychologie cognitive, Université de Lille, Laboratoire CHArt. Ses recherches portent sur le haut potentiel intellectuel, l’éducation à l’esprit critique et la modélisation mathématique en psychologie. Il est également conférencier et formateur et l’auteur de l’ouvrage <em>Les surdoués ordinaires</em> (PUF, 2014). Il a co-dirigé avec Nathalie Clobert l’ouvrage <em>Psychologie du haut potentiel</em> (De Boeck, 2021).
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/solenn.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Solenn Kermarrec</h3>
      <p>
        Pédopsychiatre, Docteur en Psychologie, Centre National d’Aide aux enfants et adolescents à Haut Potentiel (CNAEAHP). Elle a soutenu une thèse intitulée <em>Relations entre potentiel intellectuel, anxiété et dépression chez l’enfant</em> en 2017 sous la direction de Sylvie Tordjman.
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/maria.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Maria Pereira da Costa</h3>
      <p>
        Professeur de psychologie différentielle, Université Paris Descartes, Laboratoire de Psychologie et d’Ergonomie Appliquées. Ses principaux travaux de recherche portent sur les différences individuelles chez les Hauts Potentiels Intellectuels.
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/ana.png">
    </div>
    <div class="intervenant-text">
      <h3>Ana Richet</h3>
      <p>
        Psychologue clinicienne, thérapeute familiale, thérapeute ICV, chargée de cours à l’Université Paris 8 depuis 2010. Elle a travaillé au CAMPS de Courbevoie, dans le service de néonatalogie de l’hôpital Louis Mourier, au CMPP de St-Germain-en-Laye et à l’ITEP de Vauréal. Elle rédige actuellement une thèse sur l’efficacité de l’ICV pour des sujets HPI ayant développé un burn out, sous la direction de Nathalie Duriez (Laboratoire Psychopathologie et Processus de Changement à Paris 8).
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/sylvie.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Sylvie Tordjman</h3>
      <p>
        Professeur en Pédopsychiatrie à l'Université de Rennes 1 et Chef du Pôle de Psychiatrie de l'Enfant et de l'Adolescent (PHUPEA) au Centre Hospitalier Guillaume Régnier de Rennes. Laboratoire de psychologie de la perception – Université Paris-Descartes – CNRS UMR 8158. Responsable du CNAHP (Centre National d’Aide aux enfants à Haut Potentiel) qu’elle a créé en 2005. Elle accueille des enfants et adolescents à haut potentiel intellectuel et/ou créatif en difficulté. Elle a dirigé l’ouvrage <em>Aider les enfants à haut potentiel en difficulté</em> (PUR, 2010). 
      </p>
    </div>
  </div>

  <div class="intervenant">
    <div class="img-container">
      <img src="/assets/img/catherine-weismann-arcache.jpg">
    </div>
    <div class="intervenant-text">
      <h3>Catherine Weismann-Arcache</h3>
      <p>
        Maitre de Conférences Émérite en psychologie clinique et psychopathologie de l’Université de Rouen, psychologue et ancienne formatrice des psychologues de l’Education Nationale. Elle poursuit des travaux sur les hauts potentiels intellectuels depuis plus de 20 ans. Ses thématiques de recherches concernent globalement « l’anachronisme et la dysharmonie comme organisateurs contemporains des processus de pensée, de développement et de parentalité » (titre HDR). Dans cette perspective contemporaine elle s’intéresse à la révolution numérique à travers l’usage structurant versus pathologique des jeux vidéo, et aux nouvelles représentations de l’enfant via le concept d’ « humain augmenté ».
      </p>
    </div>
  </div>

</div>