import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intervenants',
  templateUrl: './intervenants.component.html',
  styleUrls: ['./intervenants.component.scss']
})
export class IntervenantsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
